<template>
    <!--Accordian-->
    <div class="accordion" role="tablist">
        <b-card no-body>
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-details variant="info">{{
                    $t('Idea Details')
                }}</b-button>
            </b-card-header>
            <b-collapse
                id="accordion-details"
                visible
                accordion="my-accordion"
                role="tabpanel"
            >
                <b-card-body>
                    <div class="idea__details">
                        <div class="idea__details--head">
                            <ul class="list-inline">
                                <li>{{ $t('Idea title') }}</li>
                                <li>{{ idea.title }}</li>
                            </ul>
                            <ul class="list-inline">
                                <li>{{ $t('Idea Owner') }}</li>
                                <li>
                                    {{
                                        idea.owner_details
                                            ? idea.owner_details.display_name
                                            : ''
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <b-card-text>
                        <div class="idea__details--desc">
                            <h5>{{ $t('Idea description') }}</h5>
                            <p>{{ idea.description }}</p>
                            <h5>{{ $t('What is your Idea about?') }}</h5>
                            <p>{{ idea.about_idea }}</p>
                            <h5>
                                {{
                                    $t(
                                        'Why are you the right person to implement this idea?',
                                    )
                                }}
                            </h5>
                            <p>{{ idea.why_you }}</p>
                        </div>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                    class="idea-document"
                    block
                    v-b-toggle.accordion-documents
                    variant="info"
                    >{{ $t('Idea Documents') }}
                </b-button>
            </b-card-header>
            <b-collapse
                id="accordion-documents"
                accordion="my-accordion"
                role="tabpanel"
            >
                <b-card-body>
                    <b-card-text>
                        <documents-list />
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                    class="team-member"
                    block
                    v-b-toggle.accordion-members
                    variant="info"
                    >{{ $t('Team Members') }}</b-button
                >
            </b-card-header>
            <b-collapse
                id="accordion-members"
                accordion="my-accordion"
                role="tabpanel"
            >
                <b-card-body>
                    <base-followers
                        :followers="idea.members"
                        :heightCondition="true"
                    ></base-followers>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>
<script>
import DocumentsList from '@/modules/ideas/components/details/DocumentsList.vue'
export default {
    props: {
        idea: Object,
    },
    components: {
        DocumentsList,
    },
}
</script>

<style lang="scss" scoped>
.accordion {
    .card {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0 0 rem(20px) 0;
        &:not(:last-child) {
            margin-bottom: 0;
        }
        .card-header {
            padding: 0 !important;
            margin: 0;
            border-bottom: none;
            background-color: transparent;
            .collapsed {
                background: rgb(52, 36, 114);
                background: linear-gradient(
                    97deg,
                    rgba(52, 36, 114, 1) 0%,
                    rgba(127, 33, 137, 1) 100%
                );
                padding: rem(20px) rem(25px);
                font-size: rem(28px);
                font-weight: 900;
                color: var(--pink);
                text-align: left;
                border-top: 0px !important;
                border-bottom: 3px solid var(--pink);
                border-radius: 0px solid transparent;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 35%;
                    right: rem(20px);
                    // background: url("assets/images/carrot-icon.svg");
                    height: 14px;
                    width: 18px;
                    background-size: cover;
                    transform: rotate(180deg);
                }
            }
            .not-collapsed {
                background: rgb(52, 36, 114);
                background: linear-gradient(
                    97deg,
                    rgba(52, 36, 114, 1) 0%,
                    rgba(127, 33, 137, 1) 100%
                );
                padding: rem(20px) rem(25px);
                font-size: rem(28px);
                font-weight: 900;
                color: var(--pink);
                text-align: left;
                border-bottom: 3px solid var(--pink);
                border-radius: 0px solid transparent;
                &:after {
                    content: '';
                    position: absolute;
                    top: 35%;
                    right: rem(20px);
                    // background: url("@/assets/images/carrot-icon.svg");
                    height: 14px;
                    width: 18px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    transform: rotate(0deg);
                }
            }
            .idea-document {
                color: #efe710;
                border-bottom: 3px solid #efe710;
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='14.969' viewBox='0 0 17 14.969'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23efe710; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M1003.94,972h15.1a0.944,0.944,0,0,0,.82-1.444c-0.33-.576-6.97-12.076-7.54-13.057a0.941,0.941,0,0,0-1.64,0c-0.41.706-7.09,12.25-7.55,13.086A0.937,0.937,0,0,0,1003.94,972Z' transform='translate(-1003 -957.031)'/%3E%3C/svg%3E%0A");
                }
            }
            .team-member {
                color: #0ab5f2;
                border-bottom: 3px solid #0ab5f2;
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='14.969' viewBox='0 0 17 14.969'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %230ab5f2; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M1003.94,972h15.1a0.944,0.944,0,0,0,.82-1.444c-0.33-.576-6.97-12.076-7.54-13.057a0.941,0.941,0,0,0-1.64,0c-0.41.706-7.09,12.25-7.55,13.086A0.937,0.937,0,0,0,1003.94,972Z' transform='translate(-1003 -957.031)'/%3E%3C/svg%3E%0A");
                }
            }
            @media screen and (max-width: 767px) {
                .collapsed,
                .not-collapsed {
                    font-size: rem(22px);
                    padding: rem(16px) rem(20px);
                    &:after {
                        height: 12px;
                        width: 14px;
                    }
                }
            }
        }
        .card-body {
            padding: rem(20px) rem(20px) 0 rem(20px);
            border-top: 0px !important;
            .idea__details {
                &--head {
                    border: 1px solid #dedee7;
                    padding: rem(20px);
                    background-color: #fff;
                    ul {
                        display: flex;
                        align-items: center;
                        &:not(:last-child) {
                            margin-bottom: rem(16px);
                        }
                        li {
                            color: #111111;
                            font-weight: 400;
                            flex: 1;
                            &:first-child {
                                flex: 0 0 136px;
                                color: #4a1885;
                                font-size: rem(20px);
                                font-weight: 600;
                            }
                        }
                    }
                }
                &--desc {
                    margin-top: rem(-10px);
                    h5 {
                        font-size: rem(18px);
                        font-weight: 600;
                        &:not(:last-child) {
                            margin-top: rem(45px);
                        }
                    }
                    p {
                        font-size: rem(16px);
                        color: #111111;
                    }
                }
                @media screen and (max-width: 575px) {
                    &--head {
                        ul {
                            li {
                                &:first-child {
                                    flex: 0 0 105px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
