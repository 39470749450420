<template>
    <div>
        <!-- Banner -->
        <base-inner-banner :banner-image="pageBanner"></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <div class="page__head">
                    <h1 class="page__head--title">
                        {{ $t('Mentorship – Idea Details') }}
                    </h1>
                    <p class="page__head--subtitle">
                        {{ mentorship.title }}
                    </p>
                </div>
                <div class="page-contents">
                    <div class="mentoridea__detail">
                        <div class="d-flex">
                            <div class="mentoridea__detail--left">
                                <div class="status-row">
                                    <h1>
                                        <span
                                            v-if="
                                                userAuth.is_mentor &&
                                                    $i18n.locale === 'ar'
                                            "
                                        >
                                            -
                                        </span>
                                        {{ mentorship.created | dateDay }}
                                        <span
                                            >{{
                                                mentorship.created
                                                    | dateMonthYear
                                            }}
                                            -
                                            {{ mentorship.created | dateTime }}
                                            <span
                                                v-if="
                                                    userAuth.is_mentor &&
                                                        $i18n.locale === 'en'
                                                "
                                            >
                                                -
                                            </span>
                                        </span>
                                    </h1>
                                    <b-dropdown
                                        v-if="
                                            userAuth.is_mentor &&
                                                status[mentorship.status]
                                        "
                                        :text="status[mentorship.status].name"
                                        :class="[
                                            status[mentorship.status].class,
                                        ]"
                                    >
                                        <b-dropdown-item
                                            v-for="(s, key) in status"
                                            :key="key"
                                            :class="[s.class]"
                                            @click="changeStatus(key)"
                                        >
                                            {{ s.name }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <span class="status-title" v-else>{{
                                        mentorship.status
                                    }}</span>
                                </div>
                                <div class="position-relative">
                                    <div class="greyBg"></div>

                                    <!-- mentoridea detail summary -->
                                    <ul
                                        class="list-inline mentoridea__detail--summary"
                                    >
                                        <li>
                                            {{ $t('Idea Status').split(' ')[0]
                                            }}<br />{{
                                                $t('Idea Status').split(' ')[1]
                                            }}
                                            <p v-if="ideaObject.state">
                                                {{
                                                    $t(
                                                        ideasStatusList[
                                                            ideaObject.state
                                                        ].text,
                                                    )
                                                }}
                                            </p>
                                        </li>
                                        <li>
                                            {{ $t('Number Of') }}<br />{{
                                                $t('The Members')
                                            }}
                                            <p>{{ ideaObject.member_count }}</p>
                                        </li>
                                        <li>
                                            {{ $t('Number Of') }}<br />{{
                                                $t('The Mentors')
                                            }}
                                            <p v-if="ideaObject.mentees">
                                                {{ ideaObject.mentees.length }}
                                            </p>
                                        </li>
                                        <li>
                                            {{ $t('Owner Name').split(' ')[0]
                                            }}<br />{{
                                                $t('Owner Name').split(' ')[1]
                                            }}
                                            <p>
                                                {{
                                                    ideaObject.owner_details
                                                        ? ideaObject
                                                              .owner_details
                                                              .display_name
                                                        : ''
                                                }}
                                            </p>
                                        </li>
                                    </ul>
                                    <!-- mentoridea detail summary -->

                                    <!-- accordion -->
                                    <idea-details
                                        :idea="ideaObject"
                                    ></idea-details>
                                    <!-- accordion -->
                                </div>
                            </div>
                            <div class="mentoridea__detail--right">
                                <!-- mentoridea breif -->
                                <div class="mentoridea__detail--breif">
                                    <b-tabs content-class="">
                                        <b-tab active>
                                            <template #title>
                                                <div class="icon">
                                                    <svg
                                                        fill="currentColor"
                                                        class="chat"
                                                        version="1.1"
                                                        id="Capa_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 417.986 417.986"
                                                        style="enable-background:new 0 0 417.986 417.986;"
                                                        xml:space="preserve"
                                                    >
                                                        <use
                                                            xlink:href="#chat"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                {{ $t('Chat') }}
                                            </template>
                                            <!-- chat -->
                                            <chat
                                                @sendMessage="sendMessage"
                                                @removeMessage="removeMessage"
                                                @lodaNext="lodaNext"
                                                :messageList="messageList"
                                                :participants="participants"
                                                :loadding="loaddingChat"
                                            ></chat>
                                            <!-- chat -->
                                        </b-tab>
                                        <b-tab>
                                            <template #title>
                                                <div class="icon">
                                                    <svg
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xml:space="preserve"
                                                        width="44.3174mm"
                                                        height="47.3707mm"
                                                        version="1.1"
                                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                                        viewBox="0 0 18831.69 20129.12"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                                                        enable-background="new 0 0 512 512"
                                                    >
                                                        <use
                                                            xlink:href="#tasks"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                {{ $t('Tasks') }}
                                            </template>
                                            <task
                                                :mentorship="mentorship"
                                            ></task>
                                        </b-tab>
                                        <b-tab>
                                            <template #title>
                                                <div class="icon">
                                                    <svg
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xml:space="preserve"
                                                        width="44.2694mm"
                                                        height="47.8013mm"
                                                        version="1.1"
                                                        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                                        viewBox="0 0 1733.37 1871.66"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                                                    >
                                                        <use
                                                            xlink:href="#notes"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                {{ $t('Notes') }}
                                            </template>
                                            <!-- notes  -->
                                            <notes
                                                :heightCondition="true"
                                                :mentorship="mentorship"
                                            ></notes>
                                            <!-- notes  -->
                                        </b-tab>
                                    </b-tabs>
                                </div>
                                <!-- mentoridea breif -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { ChatHelper } from '@/common/crud-helpers/chat'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
import { API_BASE } from '@/conf'
import IdeaDetails from '@/modules/mentorship/components/details/IdeaDetails.vue'
import Notes from '@/modules/mentorship/components/details/Notes.vue'
import Task from '@/modules/mentorship/components/details/Task.vue'
import Chat from '@/modules/users/components/messages/Chat.vue'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        IdeaDetails,
        Chat,
        Task,
        Notes,
    },
    data() {
        return {
            tabIndex: 0,
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            status: {
                new: {
                    name: this.$t('New'),
                    class: 'new',
                },
                inprogress: {
                    name: this.$t('In-Progress'),
                    class: 'in-progress',
                },
                completed: {
                    name: this.$t('Completed'),
                    class: 'compeleted',
                },
            },
            mentorship: {},
            connection: null,
            messageList: [],
            participants: [],
            loaddingChat: false,
            next: '',
            previous: '',
        }
    },
    created() {
        if (this.$route.params.uuid) {
            MentorshipHelper.get(this.$route.params.uuid)
                .then(() => {})
                .catch(() => {
                    this.$router.push({ name: 'profile' })
                })
        }
    },
    methods: {
        isActive(index) {
            return this.tabIndex == index
        },
        changeStatus(status) {
            MentorshipHelper.updateMyMentorship({
                uuid: this.uuid(this.mentorship.uuid),
                data: {
                    status: status,
                },
            }).then(resp => {
                this.mentorship.status = resp.data.status
            })
        },
        sendMessage: function(message) {
            message.author = this.userAuth.id
            message.room_uuid = this.ideaObject.uuid
            this.connection.send(JSON.stringify(message))
        },
        removeMessage: function(message) {
            let data = {
                author: this.userAuth.id,
                type: 'remove',
                data: {
                    id: message.id,
                },
                room_uuid: message.room_uuid,
            }
            this.connection.send(JSON.stringify(data))
        },
        lodaNext() {
            if (this.next) {
                this.loaddingChat = true
                let url = new URL(API_BASE + this.next)
                let cursor = url.searchParams.get('cursor')
                ChatHelper.getMoreChat({
                    cursor: cursor,
                    uuid: this.ideaObject.uuid,
                })
            }
        },
    },
    computed: {
        ...mapGetters([
            'mentorshipObject',
            'ideaObject',
            'userAuth',
            'chatList',
            'moreChatList',
            'ideasStatusList',
            'token',
        ]),
    },
    watch: {
        mentorshipObject() {
            this.mentorship = cloneDeep(this.mentorshipObject)
            IdeasHelper.getByIdIdea(this.mentorship.mentee.object_id)
        },
        ideaObject() {
            var uuid = this.ideaObject.uuid
            ChatHelper.list({
                uuid: uuid,
            })
            var token = ''
            if (this.token.length > 0) {
                token = this.token
            }
            const WS_URL = API_BASE.replace('https', 'wss')
            this.connection = new WebSocket(
                `${WS_URL}/ws/chat/${uuid}/?token=${token}`,
            )
            this.connection.onmessage = function() {
                ChatHelper.list({
                    uuid: uuid,
                })
            }
        },
        chatList() {
            this.messageList = cloneDeep(this.chatList.last_messages.messages)
            this.messageList.forEach(message => {
                if (message.author == this.userAuth.id) {
                    message.author = 'me'
                }
                message.type = 'text'
            })

            this.participants = cloneDeep(this.chatList.members)
            this.participants.forEach(participant => {
                if (participant.id == this.userAuth.id) {
                    participant.id = 'me'
                }
            })
            this.next = this.chatList.last_messages.next
        },
        moreChatList() {
            this.next = this.moreChatList.next
            let messageList = cloneDeep(this.moreChatList.results)
            messageList.forEach(message => {
                if (message.author == this.userAuth.id) {
                    message.author = 'me'
                }
                message.type = 'text'
            })
            let all = _.concat(this.messageList, messageList)
            this.messageList = cloneDeep(all)
            this.loaddingChat = false
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/.page-banner {
    --h: 620px;
    @media screen and (max-width: 1600px) {
        --h: 595px;
    }
    @media screen and (max-width: 767px) {
        --h: 400px;
    }
}
/deep/.page-contents {
    padding-top: rem(95px);
    padding-bottom: rem(70px);
    @media screen and (max-width: 1600px) {
        padding-top: rem(60px);
    }
    @media screen and (max-width: 991px) {
        padding-top: rem(150px);
    }
}
.content-wrapper {
    /deep/.page__head {
        --pagetitle: var(--skyblue);
        --subtitle: var(--white);
        &--title {
            margin-bottom: rem(100px);
            font-weight: 900;
        }
        &--subtitle {
            font-size: rem(46px) !important;
            font-weight: 900;
        }
        @media screen and (max-width: 767px) {
            padding-right: rem(15px);
            &--title {
                margin-bottom: rem(50px);
            }
            &--subtitle {
                font-size: rem(30px) !important;
            }
        }
        @media screen and (max-width: 575px) {
            &--subtitle {
                font-size: rem(20px) !important;
            }
        }
    }
}

.mentoridea__detail {
    .status-row {
        margin-bottom: rem(44px);
        h1 {
            direction: ltr;
            position: relative;
            margin-right: rem(10px);
            &:after {
                content: '';
                position: absolute;
                width: 40px;
                height: 1px;
                background: #4a1885;
                left: 0;
                bottom: -18px;
            }
            span {
                text-transform: uppercase;
            }
        }
        /deep/.dropdown-toggle {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: capitalize;
            font-size: rem(20px);
            font-weight: 600;
        }
    }
    &--summary {
        @include flex(center, space-between);
        padding-right: rem(50px);
        padding-bottom: rem(45px);
        li {
            font-size: rem(16px);
            // color: #4a1885;
            color: #613494;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 78px;
                    background: #afafcc;
                    // background: #d2d2de
                    right: -40px;
                    top: 0;
                }
            }
            p {
                color: #e4d700;
                font-size: rem(25px);
                font-weight: 700;
                margin: rem(22px) 0 0 0;
                text-transform: capitalize;
            }
        }
        @media screen and (max-width: 1300px) {
            li {
                &:not(:last-child) {
                    &:after {
                        right: -27px;
                    }
                }
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            li {
                max-width: 50%;
                flex: 0 0 50%;
                margin-bottom: rem(20px);
                &:not(:last-child) {
                    &:after {
                        right: 30px;
                    }
                }
                &:nth-child(2) {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            padding: rem(20px) rem(20px);
            li {
                &:not(:last-child) {
                    padding-right: rem(30px);
                    &:after {
                        right: 0;
                        height: 60px;
                    }
                }
            }
        }
        @media screen and (max-width: 575px) {
            /deep/li {
                max-width: 45%;
                flex: 0 0 45%;
                margin-bottom: rem(40px);
                margin-right: rem(20px);
                br {
                    display: none;
                }
                p {
                    margin-top: rem(15px);
                }
                &:nth-child(2) {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        @media screen and (max-width: 414px) {
            li {
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0 0 rem(30px) 0;
                padding: 0 0 rem(20px) 0;
                &:not(:last-child) {
                    border-bottom: 1px solid #d2d2de;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    &--breif {
        /deep/.tabs {
            &:not(.ideas-tabs) > .tab-content {
                background: transparent !important;
                padding: 0 !important;
                box-shadow: none !important;
                border: 0 !important;
                margin-top: rem(26px);
            }
            .nav-tabs {
                > .nav-item {
                    .nav-link {
                        padding: rem(28px) rem(10px);
                        color: var(--text-color);
                        height: 100%;
                        .icon {
                            margin-right: 10px;
                            color: var(--text-color);
                            svg {
                                max-width: 20px;
                                width: 20px;
                                height: auto;
                                &.chat {
                                    max-width: 25px;
                                    width: 25px;
                                }
                            }
                        }
                        &.active {
                            .icon {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        /deep/.accordion {
            .card {
                padding-left: rem(20px);
                padding-right: rem(20px);
                margin-bottom: 10px !important;
                overflow: visible;

                .card-header {
                    .btn {
                        padding-right: 18px;
                        display: flex;
                        box-shadow: none !important;
                        span {
                            text-transform: none;
                            white-space: pre-wrap;
                        }
                        &:after {
                            zoom: 0.6;
                            right: 0;
                        }
                        &.collapsed {
                            &:after {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='14.969' viewBox='0 0 17 14.969'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %234a1885; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M1003.94,972h15.1a0.944,0.944,0,0,0,.82-1.444c-0.33-.576-6.97-12.076-7.54-13.057a0.941,0.941,0,0,0-1.64,0c-0.41.706-7.09,12.25-7.55,13.086A0.937,0.937,0,0,0,1003.94,972Z' transform='translate(-1003 -957.031)'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }
            }
        }
    }
    .greyBg {
        width: 100%;
        --h: 200px;
        top: -15px;
    }
    &--left {
        flex: 1;
        width: 100%;
    }
    &--right {
        max-width: 495px;
        flex: 0 0 495px;
        margin-left: rem(30px);
    }
    @media screen and (max-width: 1300px) {
        /deep/ {
            .greyBg {
                --h: 300px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        > .d-flex {
            flex-wrap: wrap;
            flex-direction: column;
        }
        &--right {
            max-width: 100%;
            flex: 0 0 100%;
            margin: rem(30px) 0 0 0 !important;
        }
        /deep/ {
            .greyBg {
                --h: 145px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        &--breif {
            /deep/ {
                .tabs {
                    .nav-tabs {
                        > .nav-item {
                            .nav-link {
                                padding-top: rem(20px);
                                padding-bottom: rem(20px);
                                font-size: rem(20px);
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 575px) {
        /deep/ {
            .greyBg {
                --h: 230px;
            }
        }
    }
    @media screen and (max-width: 414px) {
        /deep/ {
            .greyBg {
                --h: 430px;
            }
        }
    }
}

// ar style
.ar {
    .mentoridea__detail {
        .status-row {
            h1 {
                direction: lt;
                margin-left: rem(10px);
                margin-right: 0;
                &:after {
                    right: 0;
                    left: auto;
                }
            }
            /deep/.dropdown-toggle {
                padding-left: 2.1875rem;
                padding-right: 0;
            }
        }
        &--summary {
            padding-left: rem(50px);
            padding-right: 0;
            li {
                &:not(:last-child) {
                    &:after {
                        left: -40px;
                        right: auto;
                    }
                }
            }
            @media screen and (max-width: 1300px) {
                li {
                    &:not(:last-child) {
                        &:after {
                            left: -27px;
                            right: auto;
                        }
                    }
                }
            }
            @media screen and (max-width: 1199px) and (min-width: 992px) {
                li {
                    &:not(:last-child) {
                        &:after {
                            left: 30px;
                            right: auto;
                        }
                    }
                }
            }
            @media screen and (max-width: 991px) {
                li {
                    &:not(:last-child) {
                        padding-left: rem(30px);
                        padding-right: 0;
                        &:after {
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
            @media screen and (max-width: 575px) {
                /deep/li {
                    margin-left: rem(20px);
                    margin-right: 0;
                }
            }
        }
        &--breif {
            /deep/.tabs {
                .nav-tabs {
                    > .nav-item {
                        .nav-link {
                            .icon {
                                margin-left: 10px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            /deep/.accordion {
                .card {
                    .card-header {
                        .btn {
                            padding-left: 18px;
                            padding-right: 0;
                            &:after {
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
        &--right {
            margin-left: 0;
            margin-right: 1.875rem;
        }
    }
}
span.status-title {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: capitalize;
    font-size: rem(20px);
    font-weight: 600;
    color: #4a1885 !important;
}
</style>
