<template>
    <div>
        <div class="postNotes">
            <b-form-textarea
                :placeholder="$t('Type your notes')"
                v-model="note"
            ></b-form-textarea>
            <button type="button" @click="addNote">
                <svg
                    fill="currentColor"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="location-arrow"
                    class="svg-inline--fa fa-location-arrow fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <use xlink:href="#ideadetails-notes-icon"></use>
                </svg>
            </button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { MentorshipHelper } from '@/common/crud-helpers/mentorship'

export default {
    components: {},
    data() {
        return {
            note: '',
        }
    },
    methods: {
        addNote() {
            if (this.note) {
                MentorshipHelper.addNote({
                    uuid: this.uuid(this.mentorshipObject.uuid),
                    details: this.note,
                }).then(resp => {
                    this.note = ''
                    this.$emit('setNote', resp.data)
                })
            }
        },
    },
    computed: {
        ...mapGetters(['mentorshipObject']),
    },
    watch: {},
}
</script>

<style lang="scss" scoped>
.postNotes {
    background: #fff;
    box-shadow: 0px 0px 17px -5px rgba(0, 0, 0, 0.4);
    padding: rem(10px) rem(15px);
    margin-top: rem(20px);
    @include flex(center, space-between);
    flex-wrap: nowrap;
    textarea {
        &.form-control {
            height: 45px !important;
            background: transparent;
            box-shadow: none;
            padding-top: rem(12px);
            border: 0 !important;
            &:focus {
                border: 0;
            }
            &::placeholder {
                color: #464646;
                font-weight: 400;
            }
        }
    }
    button {
        height: 42px;
        width: 42px;
        background-color: #cf0174;
        color: #fff;
        @include flex(center, center);
        border-radius: 100%;
        box-shadow: 0px 3px 8px rgba(53, 152, 219, 0.3);
        border: 0;
        outline: none;
        transition: 0.2s all ease-in;
        svg {
            max-width: 20px;
            width: 20px;
            height: auto;
            transition: 0.2s all ease-in;
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                background-color: #6d3479;
            }
        }
    }
}
</style>
