<template>
    <div>
        <div class="bg__white">
            <div class="accordion" role="tablist">
                <b-card no-body v-for="(task, key) in tasks" :key="key">
                    <b-card-header header-tag="header" role="tab">
                        <b-button
                            block
                            v-b-toggle="`accordion-tasks${key}`"
                            variant="info"
                            >{{ $t('Task') }} {{ key + 1
                            }}<span>{{ task.details }}</span></b-button
                        >
                    </b-card-header>
                    <b-collapse
                        :id="`accordion-tasks${key}`"
                        visible
                        accordion="tasks"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <div class="task__detail">
                                <ul class="list-unstyled">
                                    <li>{{ $t('Assigned To') }}:</li>
                                    <li class="name">
                                        {{ task.assignee_detail.display_name }}
                                    </li>
                                </ul>
                                <ul class="list-unstyled">
                                    <li>{{ $t('Task Status') }}:</li>
                                    <li>
                                        <base-select
                                            v-model="task.status"
                                            :options="status"
                                            @input="changeStatus(task)"
                                        ></base-select>
                                    </li>
                                </ul>
                                <ul class="list-unstyled">
                                    <li>{{ $t('Due Date') }}:</li>
                                    <li class="date">
                                        {{ task.due_date | dateDayMonthYear }}
                                    </li>
                                </ul>
                                <ul class="list-unstyled">
                                    <li>&nbsp;</li>
                                    <li
                                        class="pdf"
                                        v-for="(file, key) in task.files"
                                        :key="key"
                                    >
                                        <a :href="file.file" target="_blank">
                                            <div class="icon">
                                                <svg
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xml:space="preserve"
                                                    width="45.4969mm"
                                                    height="48.2824mm"
                                                    version="1.1"
                                                    style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                                                    viewBox="0 0 1347.23 1429.71"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                                                >
                                                    <use
                                                        xlink:href="#pdf-icon"
                                                    ></use>
                                                </svg>
                                            </div>
                                            {{ getFileName(file.file) }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <router-link
                v-if="userAuth.is_mentor && mentorship.status !== 'completed'"
                href="#"
                class="add-task"
                :to="{
                    name: 'profile',
                    query: {
                        addTask: true,
                    },
                }"
            >
                <span>
                    <i class="fas fa-plus"></i>
                </span>
                {{ $t('Add task to the mentees') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
export default {
    props: {
        mentorship: {
            type: Object,
        },
    },
    data() {
        return {
            status: [
                { value: 'new', text: this.$t('New') },
                { value: 'inprogress', text: this.$t('In-Progress') },
                { value: 'completed', text: this.$t('Completed') },
            ],
            tasks: [],
        }
    },
    created() {},
    methods: {
        changeStatus(task) {
            MentorshipHelper.updateTask({
                uuid: this.uuid(this.mentorshipObject.uuid),
                id: task.id,
                data: {
                    status: task.status,
                },
            })
        },
    },
    computed: {
        ...mapGetters([
            'mentorshipObject',
            'listTask',
            'userAuth',
            'ideaObject',
        ]),
    },
    watch: {
        mentorshipObject() {
            MentorshipHelper.listTask({
                uuid: this.uuid(this.mentorshipObject.uuid),
                limit: 99999,
            })
        },
        listTask() {
            this.tasks = cloneDeep(this.listTask.results)
        },
    },
}
</script>
<style lang="scss" scoped>
.mentoridea__detail {
    &--breif {
        .task__detail {
            display: flex;
            flex-wrap: wrap;
            margin-top: rem(22px);
            ul {
                max-width: 50%;
                flex: 0 0 50%;
                margin-bottom: rem(16px);
                li {
                    color: #243859;
                    &:first-child {
                        margin-bottom: rem(13px);
                    }
                    &.name {
                        font-size: rem(25px);
                        font-weight: 700;
                        color: #95169e;
                        padding-top: rem(15px);
                    }
                    &.date {
                        color: #ce0073;
                        font-size: rem(18px);
                        font-weight: 700;
                    }
                    .icon {
                        margin-right: 7px;
                        svg {
                            max-width: 30px;
                            height: auto;
                        }
                    }
                    a {
                        color: #0ab5f2;
                        display: flex;
                        align-items: center;
                        word-break: break-word;
                        line-height: 1.2;
                        @media screen and (min-width: 1025px) {
                            &:hover {
                                color: #ce0073;
                            }
                        }
                    }
                }
            }
            .v-select {
                box-shadow: -2px 0px 20px rgba(0, 0, 0, 0.15);
                /deep/.v-select-toggle {
                    padding: rem(14px) rem(22px) !important;
                    color: #009b74 !important;
                    font-weight: 400 !important;
                    font-size: rem(18px) !important;
                    .arrow-down {
                        right: 22px;
                        zoom: 0.5;
                    }
                    &:active,
                    &:focus {
                        color: var(--white) !important;
                    }
                }
            }
        }
        .add-task {
            font-size: rem(24px);
            font-weight: 700;
            color: #e4d700;
            @include flex(center, flex-start);
            padding-left: rem(12px);
            margin-top: rem(35px);
            span {
                @include flex(center, center);
                background: #e4d700;
                width: rem(42px);
                height: rem(42px);
                border-radius: 100%;
                position: relative;
                color: #fff;
                margin-right: 10px;
            }
        }
        .accordion {
            .card {
                -webkit-box-shadow: 0px 0px 10px 0px rgba(222, 96, 161, 0.2);
                -moz-box-shadow: 0px 0px 10px 0px rgba(222, 96, 161, 0.2);
                box-shadow: 0px 0px 10px 0px rgba(222, 96, 161, 0.2);
                .card-header {
                    .not-collapsed,
                    .collapsed {
                        font-weight: 700;
                        &:after {
                            top: 40%;
                        }
                    }
                }
                .card-body {
                    padding-bottom: rem(10px);
                }
            }
        }
        @media screen and (max-width: 500px) {
            .task__detail {
                ul {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                .v-select {
                    font-size: rem(16px) !important;
                }
            }
        }
    }
}

.ar {
    .mentoridea__detail {
        &--breif {
            .task__detail {
                ul {
                    li {
                        .icon {
                            margin-left: 7px;
                            margin-right: 0;
                        }
                    }
                }
                .v-select {
                    /deep/.v-select-toggle {
                        .arrow-down {
                            left: 22px;
                            right: auto;
                        }
                    }
                }
            }
            .add-task {
                padding-right: rem(12px);
                padding-left: 0;
                span {
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
