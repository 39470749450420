<template>
    <div>
        <div
            class="scrollWrapper"
            :style="
                heightCondition
                    ? { 'min-height': '300px', height: 'auto' }
                    : { height: '512px' }
            "
        >
            <scrollbar :ops="scrollSettings">
                <div class="bg__white">
                    <div class="mentoridea__detail--notes">
                        <div v-for="(note, key) in notes" :key="key">
                            <div class="mentoridea__detail--notes--title">
                                {{ $t('Note') }} {{ key + 1 }}
                            </div>
                            <p style="margin: 0 0 2rem 0;">
                                {{ note.details }}
                            </p>
                        </div>
                    </div>
                </div>
            </scrollbar>
        </div>
        <add-note
            @setNote="setNote"
            v-if="mentorship.status !== 'completed'"
        ></add-note>
    </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
import AddNote from '@/modules/mentorship/components/details/AddNote.vue'

export default {
    components: {
        AddNote,
    },
    props: {
        heightCondition: {
            type: Boolean,
            default: false,
        },
        mentorship: {
            type: Object,
        },
    },
    data() {
        return {
            scrollSettings: {
                maxHeight: 250,
            },
            notes: [],
        }
    },
    methods: {
        setNote(note) {
            this.notes.push(note)
        },
    },
    computed: {
        ...mapGetters(['mentorshipObject', 'listNote']),
    },
    watch: {
        mentorshipObject() {
            MentorshipHelper.listNote({
                uuid: this.uuid(this.mentorshipObject.uuid),
                limit: 99999,
            })
        },
        listNote() {
            this.notes = cloneDeep(this.listNote.results)
        },
    },
}
</script>

<style lang="scss" scoped>
.scrollWrapper {
    height: 503px;
    box-shadow: -10px 10px 12px rgba(0, 0, 0, 0.15);
    /deep/.bg__white {
        box-shadow: none;
    }
    @media screen and (max-width: 1700px) {
        height: 512px;
    }
    @media screen and (max-width: 991px) {
        height: 288px;
    }
}
.mentoridea__detail {
    &--notes {
        &--title {
            color: #4a1885;
            font-weight: 700;
            margin-bottom: rem(10px);
            text-transform: uppercase;
        }
        p {
            color: #243859;
            margin: 0 0 rem(32px) 0;
            line-height: 1.4;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.ar .scrollWrapper .mentoridea__detail--notes {
    text-align: right;
}
</style>
